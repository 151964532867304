@keyframes gradientSwoosh {
  0% {
    background-position: 50% 50%;
    background-size: 150% 125%;
  }
  15% {
    background-position: 75% 40%;
    background-size: 130% 115%;
  }
  30% {
    background-position: 50% 50%;
    background-size: 120% 135%;
  }
  60% {
    background-position: 30% 50%;
    background-size: 120% 135%;
  }
  80% {
    background-position: 10% 30%;
    background-size: 130% 155%;
  }
  100% {
    background-position: 40% 45%;
    background-size: 125% 135%;
  }
}

.loadingAnswer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 936px;
  width: 100%;
  height: 390px;
  background-image: linear-gradient(
      60deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(95, 242, 174, 0.15) 100%
    ),
    linear-gradient(284.81deg, #ffffff 67.18%, #91bbff 158.87%),
    linear-gradient(
      29deg,
      rgba(145, 187, 255, 0.6) 0%,
      rgba(255, 255, 255, 1) 40%,
      rgba(95, 242, 174, 0.6) 100%
    );

  background-size: 150% 125%;
  background-repeat: no-repeat;
  animation: gradientSwoosh 2s ease-out infinite alternate;
  border-radius: 8px;
}
