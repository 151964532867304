@import '../../../../styles/variables.scss';

.errorCard {
  width: 100%;
  background: var(--wds-color-black-750);
  padding: var(--wds-space-400);
  box-sizing: border-box;
  white-space: pre-line;

  .cta {
    color: var(--wds-color-blue-100);
    cursor: pointer;

    &:hover {
      color: var(--wsr-color-B20);
    }
  }
}
