@import '~@wix/cc-user-facing-common-components/src/styles/typography';
@import '~@wix/cc-user-facing-common-components/src/styles/variables';


#helpcenter {
  --agent-toolbar-size: 0px;
  --breadcrumbs-size: 54px;
  --header-size: 71px;

  @include mobile-only() {
    --breadcrumbs-size: 43px;
  }

  @include small-screen {
    --breadcrumbs-size: 43px;
  }

  &.agent-toolbar-open {
    --agent-toolbar-size: 43px;
  }

  &.agent-toolbar-close {
    --agent-toolbar-size: 10px;
  }
}
