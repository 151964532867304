@import '../../../../../styles/variables.scss';

.basedOnArticlesWrapper {
  align-items: center;
  flex-wrap: wrap;

  .readMore {
    padding-top: 3px;
  }

  .readMoreArticleLink {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    margin: 0;
    padding-top: 3px;
    align-items: center;

    svg path {
      fill: var(--wds-color-blue-100);
    }

    .name {
      color: var(--wds-color-blue-100);
    }

    &:hover {
      svg path {
        fill: var(--wds-color-blue-200);
      }

      .name {
        color: var(--wds-color-blue-200);
      }
    }
  }
}
