.wrapper {
  .answerProhibited {
    display: flex;
    height: var(--wds-space-1200);
    background: var(--wds-color-black-750);
    padding: var(--wds-space-400);
    box-sizing: border-box;
  }

  .generatedAnswerCard {
    background-color: var(--wds-color-black-750);
    padding: var(--wds-space-400);

    .infoWrapper {
      display: flex;
      align-items: center;
      padding-top: var(--wds-space-200);
      color: var(--wds-color-black-300);
    }

    .generatedAnswer {
      display: flex;
      flex-direction: column;

      p {
        padding-left: var(--wds-space-300);
      }

      li {
        list-style-position: inside;
        list-style-type: disc;
      }

      li::before {
        display: none;
      }
    }

    .horizontalDivider {
      margin-bottom: 16px;
      margin-top: 16px;
    }

    a {
      color: var(--wds-color-black-100) !important;

      &:hover {
        color: var(--wds-color-black-200) !important;
      }
    }
  }
}
