@import '../../../../styles/variables.scss';

.popoverFeedback {
  color: var(--wds-color-white);
  background: var(--wds-color-black-100);
  border-radius: 8px;
  box-sizing: border-box;
  font-size: var(--wds-space-200);
  line-height: 15px;
}

.footerMoreInfoAndVoting {
  justify-content: space-between;
}

.nextGenAnswerFooterInner {
  position: relative;
  display: flex;

  &.noMoreInfoArticlesShorter {
    padding-top: 0px;
    justify-content: space-between;
    width: 100%;
  }

  .readMoreArticlesWrapper {
    align-items: center;
    flex-wrap: wrap;

    .readMore {
      padding-top: 3px;
    }

    .readMoreArticleLink {
      display: flex;
      flex-direction: row;
      text-decoration: none;
      margin: 0;
      padding-top: 3px;
      padding-left: 4px;
      align-items: center;

      svg path {
        fill: var(--wds-color-blue-100);
      }

      .name {
        color: var(--wds-color-blue-100);
      }

      &:hover {
        svg path {
          fill: var(--wds-color-blue-200);
        }

        .name {
          color: var(--wds-color-blue-200);
        }
      }
    }
  }

  .genAnswerFeedback {
    &.noMoreInfoArticlesLift {
      position: absolute;
      visibility: visible;
      bottom: -6px;
      right: 0;
    }

    .verticalDivider {
      box-sizing: border-box;
      margin-left: var(--wds-space-400);
      height: auto;
    }

    .feedbackOptionsWrapper {
      justify-content: center;

      .feedbackOptions {
        padding-left: var(--wds-space-200);
        display: flex;
        justify-content: flex-end;
        gap: var(--wds-space-100);
      }
    }
  }
}

.notHelpfulFeedbackCard {
  margin-top: var(--wds-space-200);
  padding: var(--wds-space-200) var(--wds-space-300) var(--wds-space-300);
  height: 90px;
  box-sizing: border-box;

  &.withFloatingNotification {
    height: 140px;
    transition: height 0.2s;
  }

  .title {
    padding-bottom: var(--wds-space-200);
    display: flex;
    background-color: none;

    .optional {
      color: var(--wds-color-black-400);
      padding-left: 3px;
    }
  }

  .floatingNotification {
    margin-top: var(--wds-space-300);
    width: 100%;
    max-width: 100%;
  }

  .badge {
    >* {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: normal;
    }

    &:hover {
      cursor: pointer;

      &:not(.selected) {
        background-color: var(--wds-color-white);
        border-color: #a8caff;

        span {
          color: var(--wds-color-blue-100) !important;
        }
      }
    }
  }
}
