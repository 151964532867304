@import './colors';
@import './typography';

$max-mobile-width: 767px;
$min-desktop-width: 768px;
$max-mobile-landscape-width: 1023px;
$max-desktop-width: 1216px;
$article-content-width: 830px;
$z-index-top: 10000;

$STUDIO-COLOR-LIGHT-BLUE: #c0e0ff;
$STUDIO-COLOR-LIGHT-ORANGE: #fecfbb;
$STUDIO-COLOR-DARK-ORANGE: #ff8044;
$STUDIO-COLOR-DARK-BLUE: #06063a;
$STUDIO-COLOR-YELLOW: #d7ff00;

@mixin mobile-only() {
  @media screen and (max-width: $max-mobile-width) {
    @content;
  }
}

@mixin desktop-only() {
  @media screen and (min-width: $min-desktop-width) {
    @content;
  }
}

@mixin small-screen() {
  @media screen and (min-width: $min-desktop-width) and (max-width: $max-desktop-width) {
    @content;
  }
}

@mixin mobile-landscape() {
  @media screen and (max-width: $max-mobile-landscape-width) and (orientation: landscape) {
    @content;
  }
}

@mixin rtl() {
  &:lang(he) {
    @content;
  }
}
