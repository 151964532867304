$heading-font-family: 'Madefor Display',
'Helvetica Neue',
Helvetica,
Arial;
$heading-bold-font-weight: 700;

$rtl-label-font-size: 10px;

@mixin HeaderBoldBasics() {
  font-family: $heading-font-family !important;
  font-weight: $heading-bold-font-weight !important;
}

@mixin Header1Bold() {
  @include HeaderBoldBasics();
  font-size: 40px !important;
  line-height: 48px !important;
}

@mixin Header2Bold() {
  @include HeaderBoldBasics();
  font-size: 32px !important;
  line-height: 40px !important;
}

@mixin Header3Bold() {
  @include HeaderBoldBasics();
  font-size: 24px !important;
  line-height: 32px !important;
}

@mixin Header4Bold() {
  @include HeaderBoldBasics();
  font-size: 20px !important;
  line-height: 28px !important;
}

@mixin Header5Bold() {
  @include HeaderBoldBasics();
  font-size: 18px !important;
  line-height: 26px !important;
}

@mixin Header6Bold() {
  @include HeaderBoldBasics();
  font-size: 16px !important;
  line-height: 24px !important;
}
