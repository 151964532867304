@import '../../../styles/variables.scss';

.genAnswerHeader {
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: var(--wds-space-300);

  .title {
    max-width: 840px !important;
    @include Header3Bold();

    .aiAnswer {
      background: linear-gradient(81.61deg,
          #0021ff -5.05%,
          #116dff 4.35%,
          #5ff2ae 90.57%);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }

    .searchQuestion {
      padding-left: var(--wds-space-100);
      padding-right: var(--wds-space-100);
    }
  }

  .betaDisclaimer {
    width: 68px;
    font-size: var(--FontTextfont-size-body-small);
    font-weight: 500;
    letter-spacing: var(--Fontfont-letter-spacing-0);
  }
}

.aiIconWrapper {
  align-items: center;
}
