@import '../../styles/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(936px + 2 * var(--wds-space-600));
  max-width: calc(100% - var(--wds-space-1200));
  margin: 0 auto;
  box-sizing: border-box;
  padding: var(--wds-space-700) var(--wds-space-600) 0 var(--wds-space-600);

  @include mobile-only() {
    display: none;
  }

  @include small-screen() {
    display: none;
  }

  .innerWrapper {
    width: 100%;
  }
}
